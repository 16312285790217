<template>
  <component :is="as" :for="forValue" class="flex items-center space-x-1 text-gray-500">
    <span :class="boldTitle ? 'font-bold font-title block' : 'text-sm font-medium'">
      <slot></slot>
    </span>
    <span v-if="required" class="pointer-events-none text-red-300 text-sm font-medium"> * </span>
  </component>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  required: Boolean,
  boldTitle: Boolean,
  as: {
    type: String,
    default: "label",
    validator(value) {
      return ["label", "legend", "span"].includes(value)
    },
  },
})

const forValue = computed(() => (props.type !== "span" ? props.id : undefined))
</script>
